<template>
  <div>
    <div class="message_noinfo">暂无消息</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.message_noinfo {
  text-align: center;
  margin-top: 1rem;
}
</style>